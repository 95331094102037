
// 授信流水列表枚举
import web_credit_flowTypeList from "@/lib/data-service/default/web_credit_flowTypeList";
// 授信账户详情
import web_credit_creditFlow from "@/lib/data-service/default/web_credit_creditFlow";
// 设置备注
import web_credit_creditFlowRemark from '@/lib/data-service/default/web_credit_creditFlowRemark';
// 导出表格
import web_credit_creditFlowExport from '@/lib/data-service/default/web_credit_creditFlowExport';

import { Loading } from 'element-ui';
export default {
  data() {
    return {
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        tradingHourStart: "",
        tradingHourEnd: "",
        flowType: "",
        businessAccountType: "",
        businessType: "",
        type: 2,
      },
      dateRange: null,
      list: [],
      balanceForm: {
        creditUsableAmount: 0,
        travelUsableAmount: 0,
        totalLimitAmountText: 0,
      },

      pager: {
        total: 0,
        pageSizes: [10, 15, 20, 25, 30],
      },

      dateIndex: "",
      dateOptions: [
        { text: "今天", value: "1", diff: 0, type: "days" },
        { text: "最近一周", value: "2", diff: 1, type: "weeks" },
        { text: "最近一个月", value: "3", diff: 1, type: "months" },
        { text: "最近三个月", value: "4", diff: 3, type: "months" },
      ],
      flowType: [],
      businessAccountType: [],
      businessType: [],
    };
  },
  methods: {
    req_list() {
      if (this.loading) return;
      this.loading = true;
      web_credit_creditFlow(this.form)
        .then((result) => {
          const balanceForm = this.balanceForm;
          const { pageResult, creditUsableAmount, travelUsableAmount, totalLimitAmountText } = result;
          const { pageData, totalCount } = pageResult;
          this.list = pageData;
          this.pager.total = totalCount;
          balanceForm.creditUsableAmount = creditUsableAmount;
          balanceForm.travelUsableAmount = travelUsableAmount;
          balanceForm.totalLimitAmountText = totalLimitAmountText;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    req_set_remark (params) {
      let loadingInstance = Loading.service({text: '请求中...'});
      web_credit_creditFlowRemark(params).then((result) => {
        loadingInstance.close();
        this.req_list();
      }).catch((err) => {
        loadingInstance.close();
      });
    },

    req_Export () {
      web_credit_creditFlowExport().then((result) => {
        this.$message.success('导出成功');
        window.open('http://'+result.result, '_blank');
      }).catch((err) => {
        
      });
    },


    init () {
      web_credit_flowTypeList().then((result) => {
        const {flowTypeList, businessAccountTypeList, businessTypeList} = result;
        this.flowType = flowTypeList;
        this.businessType = businessTypeList.filter(item => !['3'].includes(item.type));
        this.businessAccountType = businessAccountTypeList;
        this.req_list();
      }).catch((err) => {
        
      });
    },
    search() {
      this.change_currentPage(1);
    },

    change_date(val) {
      const form = this.form;
      if (val) {
        const moment = this.$moment;
        const now = moment();
        const endSrt = moment(now).format("YYYY-MM-DD");
        const dateOptions = this.dateOptions;
        form.tradingHourStart = val[0];
        form.tradingHourEnd = val[1];
        for (let i = 0; i < dateOptions.length; i++) {
          const item = dateOptions[i];
          const { value, diff, type } = item;
          const startStr = moment(endSrt)
            .add(-diff, type)
            .format("YYYY-MM-DD");
          if (
            form.tradingHourStart === startStr &&
            form.tradingHourEnd === endSrt
          ) {
            this.dateIndex = value;
            return this.search();
          } else {
            this.dateIndex = "";
          }
        }
      } else {
        form.tradingHourStart = "";
        form.tradingHourEnd = "";
      }

      this.search();
    },
    selec_date_option(option) {
      const { value, diff, type } = option;
      this.dateIndex = value;
      const form = this.form;
      const moment = this.$moment;
      const tradingHourStart = moment();
      form.tradingHourStart = moment(tradingHourStart)
        .add(-diff, type)
        .format("YYYY-MM-DD");
      form.tradingHourEnd = tradingHourStart.format("YYYY-MM-DD");
      this.dateRange = [form.tradingHourStart, form.tradingHourEnd];
      this.search();
    },

    change_type(value, key) {
      this.form[key] = value;
      this.search();
    },

    set_remark(flowId) {
      this.$prompt("请输入备注信息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请输入备注内容",
      })
        .then(({ value }) => {
          this.req_set_remark({flowId, remark: value});
        })
        .catch(() => {
          
        });
    },

    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list();
    },

    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.change_currentPage(1);
    },
  },
  created() {
    this.init();
  },
  activated() {},
};
