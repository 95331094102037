const __request = require(`./__request`);
/**
 * 授信流水列表枚举 http://219.128.52.2:18001/doc.html#/credit/credit-account-controller/flowTypeListUsingPOST
 */
export default function(val){
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/credit/flowTypeList"
    pParameter.data = val
    return new Promise(function(resolve, reject){
        resolve(__request(pParameter))
    })
}
// 